<template>
    <div>

        <Head :title="'生成自定义进场编码'" :out="`Recognition`" />
        <div class="queryWarper" id="tops" ref="scrollRef">
            <van-search v-model="value" show-action placeholder="请输入搜索关键词" @search="onSearch">
                <select v-model="label" slot="label">
                    <option value="carNumberPlate">车牌号：</option>
                    <option value="vin">车架号：</option>
                    <option value="engineNumber">发动机号</option>
                    <option value="defined_num">自定义编号：</option>
                </select>
                <template #action>
                    <div @click="onSearch">搜索</div>
                </template>
            </van-search>

            <div class="list" v-if="Object.values(listData).length > 0">
                <div class="info">
                    <img style="width:100px;height:100px;flex:1" :src="images" alt="">
                    <div style="flex:2;margin-left: 10px">
                        <p style="font-weight: 700;">自定义编号：{{ listData.defined_num }}</p>
                        <p>进厂时间：{{ listData.entryTime }}</p>
                        <p>车牌号：{{ listData.carNumberPlate }}</p>
                    </div>
                </div>
                <br>
                <table class="table_box" width="100%" cellspacing=0 align="center">
                    <tr>
                        <th>是否监销</th>
                        <th>是否注销</th>
                        <th>是否结算</th>
                        <th>拆解状态</th>
                        <th>是否合格</th>
                    </tr>
                    <tr>
                        <td>{{ listData.is_sale_of == 1 ? '是' : '否' }}</td>
                        <td>{{ listData.isDMVCancellation == 0 ? '未注销' : '已注销' }}</td>
                        <td>{{ listData.isbilling == 0 ? '未结账' : '已结账' }}</td>
                        <td>{{ listData.isPrepareDemolition == 1 ? '已拆解' : '未拆解' }}</td>
                        <td>
                            <span v-if="listData.isEligible == 0">不合格</span>
                            <span v-if="listData.isEligible == 1">合格</span>
                            <span v-if="listData.isEligible == 2">待审核</span>
                            <span v-if="listData.isEligible == 3">待修改</span>
                            <span v-if="listData.isEligible == 8">进场登记中</span>
                            <span v-if="listData.isEligible == 9">待录资料</span>
                        </td>
                    </tr>
                </table>
                <div class="btn-bom" :style="{ marginTop: '30px' }">

                    <van-row :style="{ marginTop: '10px' }">
                        <van-col span="24"
                            :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                            车辆自定义编码:
                        </van-col>
                    </van-row>
                    <van-row>
                        <van-col span="24"
                            :style="{ color: 'white', height: '70px', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center', lineHeight: '70px' }">
                            {{ listData.defined_num ? listData.defined_num : '未设置' }}
                        </van-col>
                    </van-row>

                    <van-form :style="{ marginTop: '30px' }">
                        <van-field label="渠道编码：" placeholder="请填写渠道编码" v-model="channelNum" />
                    </van-form>
                    <van-button :style="{ marginTop: '15px' }" type="primary" size="large"
                        @click="setDefinedNum()">生成并修改自定义编码</van-button>


                </div>
                <div class="infodata" v-if="info">
                    <infoImage @shibieInfo="shibieInfo" @shibieHou="shibieHou" @carRen="carRen" :listDataInfo="listDataInfo"
                        ref="imgRef"></infoImage>
                    <br>
                    <SubstanceInfo :info="infoData" :VinInfo="VinInfo" :carInfo="carInfo" :listDataInfo="listDataInfo"
                        ref="infoRef" />
                    <br>
                    <div>
                        <van-button type="info" @click="revampChange" size="large">修改</van-button>
                    </div>
                </div>
            </div>

            <van-popup v-model="show" :closeable="false" :close-on-click-overlay="false" position="bottom"
                :style="{ height: '100%', }">
                <carList :Getdata="Getdata" @popupCloes="popupCloes"></carList>
            </van-popup>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import { selVehicLesInfo, updateVehicles, setDefinedNum } from '@/api/carInfo'
import carList from './components/carList.vue'
import SubstanceInfo from '../AllInformationCapacity/SubstanceInfo.vue'
import infoImage from '../AllInformationCapacity/infoImage.vue'
import { Dialog, Toast } from 'vant'
export default {
    components: {
        Head, carList, infoImage, SubstanceInfo
    },
    data () {
        return {
            channelNum: '',
            label: "carNumberPlate",//label
            value: "",//值
            show: false,//弹窗
            Getdata: [],
            listData: {},
            imgs: require('../../assets/zw.jpg'),
            info: false,
            listDataInfo: {},
            VinInfo: {},
            carInfo: {},
            infoData: {},
        }
    },
    computed: {
        images () {
            let img = ''
            if (this.listData.vehicleImages) {

                img = this.listData.vehicleImages.split(',')[0]
            } else {
                img = this.imgs
            }
            return img
        }
    },
    methods: {
        shibieInfo (data) {
            this.infoData = data
        },
        shibieHou (data) {
            this.VinInfo = data
        },
        carRen (data) {
            this.carInfo = data
        },
        async setDefinedNum () {

            try {
                const { data } = await setDefinedNum({ channelNum: this.channelNum, carNumberPlate: this.listData.carNumberPlate, vin: this.listData.vin }).then(res => res)
                console.log(data)
                this.listData.defined_num = data.data
                Dialog.alert({
                    message: "当前车辆自定编码已经自动设置为" + this.listData.defined_num,
                })
            } catch {
            }
        },
        //提交
        async revampChange () {
            try {
                let arr = {}
                let arr1 = {}
                if (this.$refs.infoRef.tableData.length != 0) {
                    this.$refs.infoRef.tableData.forEach(i => {
                        let obj = {
                            [i.id]: {
                                price: i.price,
                                num: i.num
                            }
                        }
                        arr = { ...arr, ...obj }
                    })
                }
                if (this.$refs.infoRef.DetetData.length != 0) {
                    this.$refs.infoRef.DetetData.forEach(i => {
                        console.log(i)
                        let obj = {
                            [i.id]: {
                                price: i.price,
                                num: i.num
                            }
                        }
                        arr1 = { ...arr1, ...obj }
                    })
                }
                let obj = {

                    carNumberPlate: this.$refs.infoRef.isData.number,//车牌号
                    vin: this.$refs.infoRef.isData.identificationCode,//VIN
                    carOwner: this.$refs.infoRef.isData.owner,//所有人
                    address: this.$refs.infoRef.isData.address,//地址
                    useAttributeId_text: this.$refs.infoRef.isData.natureText,//使用性质
                    vehicleModel: this.$refs.infoRef.isData.model,//型号
                    vehicleBrand: this.$refs.infoRef.isData.brand,//品牌
                    carType_text: this.$refs.infoRef.isData.CarGenreText,//车辆类型
                    engineNumber: this.$refs.infoRef.isData.engineCode,//发动机号
                    registrationTime: this.$refs.infoRef.isData.recordDate,//注册日期

                    fuelTypeId_text: this.$refs.infoRef.versoData.typeOfFuel,//动力类别
                    approvedPassengersCapacity: this.$refs.infoRef.versoData.numPeople,//核定人数
                    curbWeight: this.$refs.infoRef.versoData.curbWeight,//整备质量
                    gabarite1: this.$refs.infoRef.gabarite1,//长
                    gabarite2: this.$refs.infoRef.gabarite2,//宽
                    gabarite3: this.$refs.infoRef.gabarite3,//高
                    type: this.$refs.infoRef.carData.type,//类型
                    number: this.$refs.infoRef.carData.type == 1 ? this.$refs.infoRef.carData.idcard : this.$refs.infoRef.carData.credit_code,//车主号码

                    weight: this.$refs.infoRef.batch_average_weight,//过磅重量
                    weight_type: this.$refs.infoRef.batch_average_weight_type,//单位
                    deduction_weight: this.$refs.infoRef.suttle_batch_average_weight,//  扣杂重量
                    deduction_weight_type: this.$refs.infoRef.suttle_batch_average_weight_type,//单位
                    defined_num: this.$refs.infoRef.defined_num,//自定义编号
                    purchaseType: this.$refs.infoRef.purchaseType,//采购类型
                    purchasingChannelsId: this.$refs.infoRef.purchasingChannelsId,//采购渠道
                    principalUserId: this.$refs.infoRef.principalUserId,//业务员
                    is_sale_of: this.$refs.infoRef.is_sale_of,//是否监销
                    defined_num_id: this.$refs.infoRef.vehicleTypeId,//自定义车辆类型
                    other_photos: this.$refs.infoRef.WXimg,//其他照片
                    dismantle_allocation_type: this.$refs.infoRef.dismantle_allocation_type,//拆解分配机制
                    dismantle_allocation_remark: this.$refs.infoRef.dismantle_allocation_remark,

                    quality_inspection: this.$refs.infoRef.tableData,//质检增费
                    quality_inspection_deduction: this.$refs.infoRef.DetetData,//质检扣费

                    chezhu_photo: this.$refs.imgRef.carInfo_photos,//照片
                    path: this.$refs.imgRef.path,//车牌照片
                    vin_photos: this.$refs.imgRef.vin_photos,//VIN你照片
                    reverseUrl: this.$refs.imgRef.reverseUrl,//行驶证反面
                    frontUrl: this.$refs.imgRef.frontUrl,//行驶证正面
                    destruction_photos: this.$refs.imgRef.destruction_photos,//毁形
                    cancellation_photos: this.$refs.imgRef.cancellation_photos,//注销
                    break_beam_photos: this.$refs.imgRef.break_beam_photos,//断粮
                    identification_back: this.$refs.imgRef.imageUrlF,//身份证反面
                    unique_code: this.listData.unique_code
                }
                Dialog.confirm({
                    title: '提示',
                    message:
                        `<p>请再次核对车牌号及车架号是否正正确</p><p>车牌号:${this.$refs.infoRef.isData.number}</p><p>车架号:<br>${this.$refs.infoRef.isData.identificationCode}</p>`,
                }).then(async () => {
                    const { data } = await updateVehicles(obj).then(res => res)
                    document.getElementById('tops').scrollIntoView()
                    // this.$router.go(0)
                    this.$toast.success(data.msg)
                }).catch(() => {

                })


            }
            catch { }

        },
        //选择要查询的车辆
        popupCloes (e) {
            this.show = false
            this.info = false
            if (e) {
                this.listData = e
            }
        },
        async onSearch () {
            try {

                const { data } = await selVehicLesInfo({ key: this.label, value: this.value }).then(res => res)
                this.Getdata = data.data
                this.show = true
            }
            catch { }
        }
    },
}
</script>

<style lang="scss" scoped>
.queryWarper {
    margin-top: 50px;

    .info {
        display: flex;

        .tit {
            font-size: 23px;
            font-weight: 700;
        }

        p {
            margin: 10px 0;
        }
    }

    .btn-p {
        text-align: center;
    }

    .list {
        padding: 10px;
    }
}

.table_box {
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;

    tr {
        display: flex;
    }

    td {
        border-left: 1px solid #000;
        border-top: 1px solid #000;
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    th {
        border-left: 1px solid #000;
        border-top: 1px solid #000;
        flex: 1;
    }

}

.btn-bom {
    margin-top: 10px;
}

.info {
    margin-top: 20px;
}
</style>